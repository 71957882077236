exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-2-js": () => import("./../../../src/pages/learn-2.js" /* webpackChunkName: "component---src-pages-learn-2-js" */),
  "component---src-pages-learn-3-js": () => import("./../../../src/pages/learn-3.js" /* webpackChunkName: "component---src-pages-learn-3-js" */),
  "component---src-pages-learn-android-js": () => import("./../../../src/pages/learn-android.js" /* webpackChunkName: "component---src-pages-learn-android-js" */),
  "component---src-pages-learn-interest-js": () => import("./../../../src/pages/learn-interest.js" /* webpackChunkName: "component---src-pages-learn-interest-js" */),
  "component---src-pages-learn-iphone-js": () => import("./../../../src/pages/learn-iphone.js" /* webpackChunkName: "component---src-pages-learn-iphone-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-play-2-js": () => import("./../../../src/pages/play-2.js" /* webpackChunkName: "component---src-pages-play-2-js" */),
  "component---src-pages-play-android-js": () => import("./../../../src/pages/play-android.js" /* webpackChunkName: "component---src-pages-play-android-js" */),
  "component---src-pages-play-contact-js": () => import("./../../../src/pages/play-contact.js" /* webpackChunkName: "component---src-pages-play-contact-js" */),
  "component---src-pages-play-interest-js": () => import("./../../../src/pages/play-interest.js" /* webpackChunkName: "component---src-pages-play-interest-js" */),
  "component---src-pages-play-iphone-js": () => import("./../../../src/pages/play-iphone.js" /* webpackChunkName: "component---src-pages-play-iphone-js" */),
  "component---src-pages-play-js": () => import("./../../../src/pages/play.js" /* webpackChunkName: "component---src-pages-play-js" */),
  "component---src-pages-play-web-js": () => import("./../../../src/pages/play-web.js" /* webpackChunkName: "component---src-pages-play-web-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-work-beta-js": () => import("./../../../src/pages/work-beta.js" /* webpackChunkName: "component---src-pages-work-beta-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

